export const defaultSnackbarState = {
  // open: true,
  open: false,
  // message: "test",
  message: 'This is a snackbar',
  // variant: "info"
  // variant: "error",
  // variant: "success",
  variant: '', // defaults to black
};

export const defaultDrawerState = {
  open: false,
  locked: false,
};
