import { FiCamera, FiHome,FiMap } from 'react-icons/fi';

const routes = [
  // {
  //   path: '/',
  //   exact: true,
  //   private: true,
  //   pageTitle: 'Dashboard',
  //   drawerLabel: 'Dashboard',
  //   icon: FiHome,
  // },
  {
    path: '/',
    exact: true,
    private: true,
    pageTitle: 'Create New Field Issue',
    drawerLabel: 'Field Issues',
    icon: FiCamera,
  },
  {
    path: '/map',
    exact: true,
    private: true,
    pageTitle: 'Map Screen',
    drawerLabel: 'Map',
    icon: FiMap,
  },
  {
    path: '/login',
    exact: true,
    pageTitle: 'Login',
    hidden: true,
    hideDrawer: true,
  },

  // {
  //   path: '/spinners',
  //   exact: true,
  //   private: true,
  //   pageTitle: 'Spinner Examples',
  //   drawerLabel: 'Spinners',
  //   icon: FiCamera,
  // },
];

export default routes;
